import { Button, Grid, styled, useMediaQuery } from '@mui/material';
import AppModal from 'components/AppModal';
import FlexBox from 'components/flexbox/FlexBox';
import { H2 } from 'components/Typography';
import { useTranslation } from 'react-i18next';

import { FC, useState } from 'react';
import FileUploadInput from 'components/input-fields/FileUploadInput';
import AddPaymentModal, { EmployeeEntityWithAmount } from './AddPaymentModal';
import readXlsxFile from 'read-excel-file';
import { employeesApi } from 'api';
import toast from 'react-hot-toast';
import { useSeason } from 'contexts/SeasonContext';

// component props interface
interface UpdateZipProps {
    open: boolean;
    onClose: () => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 900,
    minWidth: 200,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const UploadExcelModal: FC<UpdateZipProps> = ({ open, onClose }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery('(max-width:499px)');
    const [selectedFile, setSelectedFile] = useState<File>();
    const [employeesPayment, setEmployeesPayment] = useState<EmployeeEntityWithAmount[]>([]);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const { seasonId } = useSeason();


    const resetAll = () => {

        setSelectedFile(undefined);
        setEmployeesPayment([]);
    }

    const fetchExcel = async () => {
        if (!selectedFile)
            return;

        const readFile = (await readXlsxFile(selectedFile));

        const filtredRows: Array<{ taxCode: string, net: number }> = [];

        readFile.forEach(row => {
            const netValue = row.at(10)?.valueOf();
            if (!isNaN(Number(netValue))) {
                filtredRows.push({
                    net: Number(netValue),
                    taxCode: (row.at(9)?.valueOf() ?? '') as string
                })
            }

        })

        const employees = (await employeesApi.getEmployeesByTaxcode(filtredRows.map(row => row.taxCode), seasonId)).data;

        if (employees.length > 0) {


            setEmployeesPayment(employees.map(employee => {
                return {
                    amount: filtredRows.find(row => row.taxCode.toLowerCase() === employee.taxcode.toLowerCase())?.net,
                    ...employee
                }
            }));

            setOpenPaymentModal(true);
        }

        else {
            toast.error(t('alerts.noEmployeeWithTaxcodes'))
        }

    }


    return (
        <>
            <AddPaymentModal
                open={openPaymentModal}
                onClose={() => {
                    setOpenPaymentModal(false);
                    resetAll();
                }}
                setTableData={() => { }}
                tableData={undefined}
                employees={employeesPayment}
            />
            <StyledAppModal open={open} handleClose={() => {
                resetAll();
                onClose();
            }}>
                <H2 mb={2}>
                    {t('table.uploadExcel')}
                </H2>

                <p>
                    {t('uploadExcel.description')}
                </p>

                <form>
                    <Grid container mt={2}
                        spacing={3}
                        textAlign={isMobile ? "left" : "center"}
                        alignItems="center"
                        justifyItems={"center"}
                        justifyContent={"center"}
                    >
                        <Grid item xs={6}>
                            <FileUploadInput
                                handleOnChange={(e) => {
                                    if (e.target.files) {
                                        setSelectedFile(Array.from(e.target.files)[0]);
                                    }
                                }}
                                resetOnChange={false}
                                disabled={false}
                                multiple={false}
                            />
                        </Grid>
                    </Grid>


                    <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                        <Button fullWidth variant='outlined' onClick={() => {
                            resetAll();
                            onClose();
                        }}>
                            {t('common.forms.button.cancel')}
                        </Button>
                        <Button fullWidth
                            disabled={!selectedFile}
                            size='small'
                            type='button'
                            variant='contained'
                            onClick={fetchExcel}
                        >
                            {t('common.forms.button.upload')}
                        </Button>
                    </FlexBox>
                </form>


            </StyledAppModal>
        </>
    );
};

export default UploadExcelModal;