import axios, { AxiosInstance } from 'axios';
import {
  AccountApi,
  AgreementsApi,
  AuthApi,
  BankingInformationApi,
  ContractsApi,
  ContractTypesApi,
  DepartmentsApi,
  DocumentTypesApi,
  DepartmentsEmployeesApi,
  DepartmentsEmployeesCalendarEntriesApi,
  SegmentApi,
  EmployeeDocumentApi,
  EmployeesApi,
  ExperienceLevelApi,
  WorkPositionsApi,
  BuildingsApi,
  RoomsApi,
  StatusApi,
  EmployeeNoteApi,
  EmployeePaymentsApi,
  SeasonsApi,
  UsersApi,
  TokenApi,
  NotificationsApi,
  TenantConfigApi,
  RewardsApi,
  CommunicationsApi,
  JobAdvertisementsApi,
  LicenceApi,
  AdditionalInformationApi,
  ShiftTemplateApi,
  ShiftApi,
  CalendarEntryShiftTemplateApi,
  PaymentMethodsApi,
  SectionsApi,
  TurnoverItemsApi,
  DepositsApi,
  TenantBankingInformationApi,
  DailyNotesApi,
} from './generated';

// url of the form: http://localhost:44344
const baseURL = process.env.REACT_APP_BASE_URL;
export const apiURL = baseURL + '/api/v1/';

/**
 * @deprecated Use the corresponding API instead
 */
export const realAxios = axios.create();

realAxios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  },
);

realAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status !== 401) {
      const response = error.response;
      console.error(`Error ${response.status}: `, response.data);
      return Promise.reject(error);
    }
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return Promise.reject(error);
    }
    const { config } = error.response;

    return refreshTokens(refreshToken)
      .then(async (response) => {
        const { access_token: accessToken, refresh_token: refreshToken } =
          response.data;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        // redo call with updated access token
        config.headers.Authorization = `Bearer ${accessToken}`;
        return axios(config).catch(async (error) => {
          console.error(error);
          return Promise.reject(error);
        });
      })
      .catch(async (e) => {
        if (e.status === 401) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
        }
        return Promise.reject(error);
      });
  },
);

async function refreshTokens(refreshToken: string) {
  // use a fresh instance of axios to prevent an infinite loop in the interceptors
  return axios.get('auth/refresh', {
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
}

const params: [undefined, string | undefined, AxiosInstance] = [
  undefined,
  baseURL,
  realAxios,
];

export const departmentsApi = new DepartmentsApi(...params);
export const segmentApi = new SegmentApi(...params);
export const contractTypesApi = new ContractTypesApi(...params);
export const authApi = new AuthApi(...params);
export const workPositionsApi = new WorkPositionsApi(...params);
export const experienceLevelApi = new ExperienceLevelApi(...params);
export const contractsApi = new ContractsApi(...params);
export const idDocumentsApi = new EmployeeDocumentApi(...params);
export const bankingInformationApi = new BankingInformationApi(...params);
export const employeesApi = new EmployeesApi(...params);
export const departmentsEmployeesApi = new DepartmentsEmployeesApi(...params);
export const accountApi = new AccountApi(...params);
export const calendarEntriesApi = new DepartmentsEmployeesCalendarEntriesApi(...params);
export const agreementApi = new AgreementsApi(...params);
export const buildingApi = new BuildingsApi(...params);
export const roomApi = new RoomsApi(...params);
export const documentTypesApi = new DocumentTypesApi(...params);
export const statusApi = new StatusApi(...params);
export const employeeNotesApi = new EmployeeNoteApi(...params);
export const communicationsApi = new CommunicationsApi(...params);
export const employeePaymentsApi = new EmployeePaymentsApi(...params);
export const seasonApi = new SeasonsApi(...params);
export const userApi = new UsersApi(...params);
export const tokenApi = new TokenApi(...params);
export const notificationApi = new NotificationsApi(...params);
export const tenantConfigApi = new TenantConfigApi(...params);
export const rewardApi = new RewardsApi(...params);
export const jobAdvertisementsApi = new JobAdvertisementsApi(...params);
export const licenceApi = new LicenceApi(...params);
export const additionalInformationsApi = new AdditionalInformationApi(...params);
export const shiftTemplateApi = new ShiftTemplateApi(...params);
export const shiftApi = new ShiftApi(...params);
export const calendarEntriesShiftTemplateApi = new CalendarEntryShiftTemplateApi(...params);
export const paymentMethodsApi = new PaymentMethodsApi(...params);
export const paymentSectionsApi = new SectionsApi(...params);
export const turnoverApi = new TurnoverItemsApi(...params);
export const depositsApi = new DepositsApi(...params);
export const tenantBankingInformationApi = new TenantBankingInformationApi(...params);
export const dailyNotesApi = new DailyNotesApi(...params);
