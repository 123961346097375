import { Button, Grid, styled, useMediaQuery } from "@mui/material";
import AppModal from "components/AppModal";
import CalendarInput from "components/input-fields/CalendarInput";
import { H1 } from "components/Typography";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

interface ModalProps {
    onSubmit: (newDate: Date) => void;
    open: boolean;
    onClose: () => void;
}

const StyledModal = styled(AppModal)(({ theme }) => ({
    width: useMediaQuery(theme.breakpoints.down('sm')) ? '100%' : 500,
    maxHeight: useMediaQuery(theme.breakpoints.down('sm')) ? '80%' : 800,
    overflowY: 'auto',
}));

const DatePickerModal: FC<ModalProps> = ({ onSubmit, onClose, open }) => {

    const [date, setDate] = useState(new Date());

    const { t } = useTranslation();

    const handleClose = () => {
        setDate(new Date());
        onClose();
    }

    return (
        <StyledModal
            handleClose={handleClose}
            open={open}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <H1>
                        {t('turnover.move')}
                    </H1>
                </Grid>
                <Grid item xs={12}>
                    <CalendarInput
                        format='dd.MM.yyyy'
                        value={date}
                        onChange={(value) => {
                            if (value && !isNaN(+value))
                                setDate(value);
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth size='small' variant='outlined' onClick={handleClose}>
                        {t('common.forms.button.cancel')}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth size='small' type="submit" variant='contained' onClick={() => {
                        onSubmit(date);
                        handleClose();
                    }}>
                        {t('common.forms.button.send')}
                    </Button>
                </Grid>
            </Grid>


        </StyledModal>
    )
}

export default DatePickerModal;