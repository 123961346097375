import {
    Box,
    Card,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useTheme,
} from '@mui/material';
import { EmployeeEntity, PaymentMethodEntity, SectionEntity, TurnoverItemEntity } from 'api/generated';
import { H5, Small } from 'components/Typography';
import { format } from 'date-fns';
import NoData from 'page-sections/dashboards/dashboard-components/no-data';
import { useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'simplebar-react';
import { translatePaymentTypes } from 'utils/convertPaymentTypes';
import { formatToItalianNumber } from 'utils/utils';

const commonCSS = {
    minWidth: 120,
    '&:nth-of-type(1)': { minWidth: 170 },
};

// Styled components
const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.secondary,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': { paddingLeft: '1.5rem' },
    //   "&:last-of-type": { paddingRight: 0 },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
    //   padding: 0,
    fontSize: 12,
    fontWeight: 500,
    paddingLeft: '1.5rem',
    //   paddingTop: "1rem",
    '&:first-of-type': { paddingLeft: '1.5rem' },
    //   "&:last-of-type": { paddingRight: 0 },
    [theme.breakpoints.down('sm')]: { ...commonCSS },
    [theme.breakpoints.between(960, 1270)]: { ...commonCSS },
}));

const StyledTableBody = styled(TableBody)(() => ({
    marginTop: '50px',
}));

interface LastTurnoverItemsProps {
    turnoverItems: TurnoverItemEntity[];
    methods: PaymentMethodEntity[];
    sections: SectionEntity[];
    employees: EmployeeEntity[];
}

const LastTurnoverItems: FC<LastTurnoverItemsProps> = ({
    turnoverItems,
    methods,
    sections,
    employees
}) => {
    const [items, setItems] = useState<TurnoverItemEntity[]>([]);

    useEffect(() => {
        const tempItems = turnoverItems
            .filter((item) => {
                return item.value !== 0;
            })
            .sort((a, b) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
            })
            .slice(0, 10);
        setItems(tempItems);
    }, [turnoverItems])

    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Card>
            <H5 mx={3} mt={3} mb={1}>
                {t('turnover.lastItems.Title')}
            </H5>

            {
                turnoverItems.length === 0 ?
                    <NoData /> :
                    <ScrollBar style={{ maxHeight: '300px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeadCell>{t('turnover.value')}</StyledTableHeadCell>
                                    <StyledTableHeadCell>{t('Date')}</StyledTableHeadCell>
                                    <StyledTableHeadCell>{t('menu.apps.section')}</StyledTableHeadCell>
                                    <StyledTableHeadCell>{t('item.method')}</StyledTableHeadCell>
                                    <StyledTableHeadCell>{t('employees.hierarchy.employee.label')}</StyledTableHeadCell>
                                    <StyledTableHeadCell>{t('status.label')}</StyledTableHeadCell>
                                </TableRow>
                            </TableHead>

                            <StyledTableBody>
                                {items.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            backgroundColor:
                                                index % 2 === 0 ? 'action.hover' : 'transparent',
                                        }}
                                    >
                                        <StyledTableBodyCell>
                                            <Box display='flex' alignItems='center'>
                                                <Small ml='1rem'>{formatToItalianNumber(item.value)} €</Small>
                                            </Box>
                                        </StyledTableBodyCell>
                                        <StyledTableBodyCell>
                                            {format(new Date(item.date), 'dd/MM/yyyy')}
                                        </StyledTableBodyCell>
                                        <StyledTableBodyCell>{sections.find((sec) => { return sec.id === item.paymentSectionId })?.name || '-'}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{
                                            translatePaymentTypes(methods.find((method) => { return method.id === item.paymentMethodId })?.type ?? '', t)
                                        }</StyledTableBodyCell>
                                        <StyledTableBodyCell>
                                            {employees.find((emp) => { return emp.id === item.employeeId })?.firstName || '-'}
                                            {' '}
                                            {employees.find((emp) => { return emp.id === item.employeeId })?.lastName || '-'}
                                        </StyledTableBodyCell>
                                        <StyledTableBodyCell
                                            sx={{
                                                color:
                                                    item.status === 'CONFIRMED'
                                                        ? theme.palette.success.main
                                                        : item.status === 'RECEIVED'
                                                            ? theme.palette.warning.main
                                                            : theme.palette.error.main
                                            }}
                                        >
                                            {t('turnoverStatus.' + item.status.toLowerCase())}
                                        </StyledTableBodyCell>
                                    </TableRow>
                                ))}
                            </StyledTableBody>
                        </Table>
                    </ScrollBar>
            }
        </Card>
    );
};

export default LastTurnoverItems;
