import { Box, Grid, Tooltip, useTheme } from "@mui/material";
import { NotificationEntity, NotificationEntityTypeEnum } from "api/generated";
import { Tiny } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { useTranslation } from "react-i18next";
import RelativeTimeFormat from "relative-time-format";
import en from "relative-time-format/locale/en";
import it from "relative-time-format/locale/it";
import de from "relative-time-format/locale/de";
import { addDays } from "date-fns";



RelativeTimeFormat.addLocale(it);
RelativeTimeFormat.addLocale(en);
RelativeTimeFormat.addLocale(de);

const clickableNotifications: NotificationEntityTypeEnum[] = ['ANNIVERSARY', 'BIRTHDAY', 'EXPIRING', 'EXPIRINGDOCUMENT', 'STARTINGCONTRACT', 'SHIFTAVAILABILITY']

const NotificationListItem = (
    props: { notification: NotificationEntity, isNew: boolean, navigateCallBack: (date: Date, type?: NotificationEntityTypeEnum) => void }) => {

    const { notification, isNew } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colorbg =
        theme.palette.mode === 'light' ? 'secondary.light' : 'divider';

    let message = '';
    let showedTime = '';
    const notificationDate = new Date(notification.sentAt)
    const today = new Date();

    const millisecondsToDays = (milliseconds: number) => {
        return Math.round(milliseconds / 1000 / 3600 / 24)
    }

    if (today.toDateString() === notificationDate.toDateString())
        showedTime = notificationDate.toLocaleTimeString('it-IT', { hour: 'numeric', minute: 'numeric' });
    else {
        showedTime = new RelativeTimeFormat(i18n.language, {
            style: "long"
        }).format(millisecondsToDays(+notificationDate - +today), 'day');
    }


    switch (notification.type) {
        case "BIRTHDAY":
            message = t('notifications.birthDayText', {
                name: notification.employeeName
            })
            break;
        case "ANNIVERSARY":
            message = t('notification.anniversary', {
                name: notification.employeeName
            })
            break;
        case "EXPIRING":
            message = t('notification.expiring', {
                name: notification.employeeName,
                days: notification.daysUntilExpiry
            })
            break;
        case "EXPIRINGDOCUMENT":
            message = t('notification.expiringDocument', {
                name: notification.employeeName,
                documentName: notification.text,
                days: notification.daysUntilExpiry
            })
            break;
        case "COMPLETEDPROFILE":
            message = t('notification.completedprofile', {
                name: notification.employeeName
            });
            break;
        case "STARTINGCONTRACT":
            message = t('notification.startContract', {
                name: notification.employeeName,
                days: 3
            })
            break;
        case "DRAFTCONTRACT":
            message = t('notification.draftContract', {
                name: notification.employeeName
            });
            break;
        case "TURNOVER":
            message = t('notification.turnover', {
                name: notification.employeeName,
                date: addDays(new Date(notification.sentAt), -1).toLocaleDateString('it-IT'),
                department: notification.text
            });
            break;
        case "SHIFTAVAILABILITY":
            message = t('notification.newShiftRequests')
            break;
    }


    return (
        <FlexBox
            p={2}
            alignItems='center'
            sx={{
                borderBottom: 1,
                cursor: 'pointer',
                borderColor: 'divider',
                backgroundColor: isNew ? colorbg : 'transparent',
                '&:hover': { backgroundColor: colorbg },
            }}
            onClick={() => {
                if (clickableNotifications.includes(notification.type))
                    props.navigateCallBack(new Date(notification.sentAt), notification.type)
            }}
        >
            <FlexBox alignItems='center'>
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        marginRight: 2,
                        borderRadius: '50%',
                        backgroundColor:
                            isNew ? 'primary.main' : 'text.disabled',
                    }}
                />
            </FlexBox>

            <Grid container>
                <Grid item xs={9}>
                    <Tiny fontWeight={500} color='text.disabled'>
                        {message}
                    </Tiny>
                </Grid>
                <Grid item xs={3} textAlign={'end'}>
                    {
                        // Known issue, tooltip doesn't show up, issue described in MT-235 
                    }
                    <Tooltip title={notificationDate.toLocaleDateString()} placement="left">
                        <Tiny fontWeight={500} color='text.disabled'>
                            {showedTime}
                        </Tiny>
                    </Tooltip >

                </Grid>
            </Grid>
        </FlexBox>
    );
}
export default NotificationListItem;