import React from 'react';
import ReactQuill from 'react-quill-new';

// Define the props interface
interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties
}

// Define the static properties interface
interface TextEditorStatic {
  modules: Record<string, unknown> | undefined;
  formats: string[];
}

// Define the TextEditor component with props type
const TextEditor: React.FC<TextEditorProps> & TextEditorStatic = ({ value, onChange, style }) => {
  return (
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={TextEditor.modules}
        formats={TextEditor.formats}
        style={style}
      />
  );
};

// Configuration of Quill modules and formats
TextEditor.modules = {
  toolbar: [
    [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline'],
    [{ 'align': [] }]
  ],
};

TextEditor.formats = [
  'header', 'font', 'list', 'bold', 'italic', 'underline', 'align'
];

export default TextEditor;
