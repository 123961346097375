import { Autocomplete, Button, Collapse, FormControlLabel, Grid, InputAdornment, Switch, TextField, Typography, styled } from "@mui/material";
import { departmentsApi, paymentMethodsApi } from "api";
import { CreateSectionDto, DepartmentEntity, PaymentMethodEntity, SectionEntity } from "api/generated";
import AppModal from "components/AppModal";
import { H2, H6 } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { useFormik } from "formik";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import * as Yup from 'yup';
import PaymentMethodDataTable from "../PaymentMethodsForAddSection";

interface ModalProps {
    data?: SectionEntity;
    open: boolean;
    onClose: () => void;
    onSubmitAction: (segment: CreateSectionDto, paymentMethods: PaymentMethodEntity[]) => void;
}


const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 500,
    minWidth: 300,
    overflow: "auto",
    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));

const AddPaymentSectionModal: FC<ModalProps> = (
    {
        data,
        open,
        onClose,
        onSubmitAction,
    }

) => {

    const [departments, setDepartemnts] = useState<DepartmentEntity[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>([]);
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState(data?.paymentMethods ?? []);

    useEffect(() => {
        departmentsApi.findAll().then(({ data }) => {
            setDepartemnts(data);
        });
        paymentMethodsApi.findAll().then(({ data }) => {
            setPaymentMethods(data);
        })
    }, []);

    useEffect(() => {
        setSelectedPaymentMethods(data?.paymentMethods ?? []);
    }, [data]);

    const initialValues = {
        name: data?.name ?? '',
        description: data?.description ?? '',
        taxPercentage: data?.taxPercentage ?? 22,
        departmentId: data?.departmentId ?? -1,
        onlyToBeShowed: data?.onlyToBeShowed ?? false,
        stillCountsDepositable: data?.stillCountsDepositable ?? false
    };

    const fieldValidationSchema = Yup.object().shape({
        name: Yup.string().required(
            t('common.forms.field.required', {
                field: t('departments.name'),
            }),
        ),
        taxPercentage: Yup
            .number()
            .required(t('payment.invalidAmmount'))
            .min(0, t('paymentSection.invalidTaxPercentage'))
            .max(100, t('paymentSection.invalidTaxPercentage'))
            .test(
                "is-decimal",
                t('paymentSection.taxPercentageDecimal'),
                (val: any) => {
                    if (val !== undefined && val !== null) {
                        const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
                        return patternTwoDigisAfterComma.test(val);
                    }
                    return true;
                }
            ),
        departmentId: Yup.number().required(t('paymentSection.invalidDepartment')).min(0, t('paymentSection.invalidDepartment'))
    });

    const { values, errors, handleChange, handleSubmit, touched, resetForm, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: fieldValidationSchema,
        onSubmit: async (values) => {
            onSubmitAction(values, selectedPaymentMethods);
            onClose();
            resetForm();
        },

    });

    return (
        <StyledAppModal
            open={open}
            handleClose={onClose}
        >

            <H2 mb={2}>
                {
                    data === undefined ? t('common.forms.addItemLabel', {
                        item: t('menu.apps.section'),
                    }) : t('common.forms.updateItemLabel', {
                        item: t('menu.apps.section')
                    })
                }
            </H2>
            <Grid container alignItems={'stretch'} >
                <Grid item xs={12}>
                    <H6 mb={1}>{t('departments.name')}</H6>
                    <AppTextField
                        fullWidth
                        size='small'
                        name='name'
                        placeholder={t('departments.name')}
                        value={values.name}
                        onChange={handleChange}
                        error={Boolean(errors.name && touched.name)}
                        helperText={(touched.name && errors.name) as string}
                    />
                </Grid>
                <Grid item xs={12}>
                    <H6 mb={1}>{t('paymentMethod.description')}</H6>
                    <AppTextField
                        fullWidth
                        size='small'
                        name='description'
                        placeholder={t('paymentMethod.description')}
                        value={values.description}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <H6 mb={1}>{t('paymentSection.taxPercentage')}</H6>
                    <AppTextField
                        type='number'
                        placeholder={t('paymentSection.taxPercentage')}
                        size='small'
                        inputProps={{
                            min: 0,
                            step: 0.01,
                        }}
                        value={values.taxPercentage}
                        helperText={touched.taxPercentage && errors.taxPercentage}
                        error={Boolean(touched.taxPercentage && errors.taxPercentage)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        onChange={(e) => {
                            const newValue = parseFloat(e.target.value);
                            if (!isNaN(newValue)) {
                                setFieldValue(`taxPercentage`, newValue);
                            } else {
                                setFieldValue(`taxPercentage`, null);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} my={2}>
                    <FormControlLabel
                        sx={{ minWidth: '0px' }}
                        label={<Typography noWrap textOverflow={'ellipsis'} overflow={'hidden'}>{t('paymentSection.onlyToBeShowed')}</Typography>}
                        labelPlacement='end'
                        control=
                        {
                            <Switch checked={values.onlyToBeShowed} onChange={() => {
                                setFieldValue('onlyToBeShowed', !values.onlyToBeShowed);
                            }} />
                        }
                    />
                </Grid>
                <Collapse in={values.onlyToBeShowed}>
                    <Grid item xs={12} my={2}>
                        <FormControlLabel
                            sx={{ minWidth: '0px' }}
                            label={
                                <Typography  textOverflow={'ellipsis'} overflow={'hidden'}>
                                    {t('paymentSection.stillCountsDepositable')}
                                </Typography>
                            }
                            labelPlacement="end"
                            control={
                                <Switch
                                    checked={values.stillCountsDepositable}
                                    onChange={() => {
                                        setFieldValue('stillCountsDepositable', !values.stillCountsDepositable);
                                    }}
                                />
                            }
                        />
                    </Grid>
                </Collapse>

                <Grid item xs={12}>
                    <H6 mb={1}>{t('paymentSection.department')}</H6>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        options={departments}
                        value={departments.find((dep) => { return (dep.id === values.departmentId) }) ?? {
                            id: -1,
                            name: t('paymentSection.invalidDepartment'),
                            segmentId: 0
                        }}
                        size='small'
                        getOptionLabel={(dep) => { return dep.name }}
                        renderInput={(params: any) =>
                            <TextField
                                {...params}
                                label={t('paymentSection.department')}
                                helperText={touched.departmentId && errors.departmentId}
                                error={Boolean(touched.departmentId && errors.departmentId)}
                            />
                        }
                        onChange={(_event, value) => {
                            setFieldValue("departmentId", value?.id)
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} maxHeight={'30vh'} overflow={'auto'}>
                <PaymentMethodDataTable
                    selectedData={selectedPaymentMethods}
                    setData={setSelectedPaymentMethods}
                    paymentMethods={paymentMethods}
                />
            </Grid>
            <FlexBox justifyContent='flex-end' gap={2} marginTop={4}>
                <Button fullWidth size='small' variant='outlined' onClick={() => {
                    onClose();
                }}>
                    {t('common.forms.button.cancel')}
                </Button>
                <Button fullWidth size='small' type='submit' variant='contained' onClick={() => { handleSubmit() }}>
                    {t('common.forms.button.save')}
                </Button>
            </FlexBox>

        </StyledAppModal>
    )
}

export default AddPaymentSectionModal;