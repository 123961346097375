import { ShiftAvailabilityEntity } from "api/generated";
import { Small } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import DataTable from "./DataTable";
import AppCheckBox from "components/AppCheckBox";

interface DataTableProps {
    data: ShiftAvailabilityEntity[];
    changeRequestState:  (index: number, state: boolean) => void
}



const ShiftRequestsDataTable: FC<DataTableProps> = (props) => {

    const { t } = useTranslation()
    const columns: any = useMemo(() => getColumnShape(), [t, props.data]);
    const timeOption: any = {
        timeZone: 'UTC',
        hour: '2-digit',
        minute: '2-digit'
    }
    
    

    function getColumnShape() {
        return [
            {
                minWidth: 50,
                Header: t('shift.itemName'),

                Cell: ({ row }: CellProps<ShiftAvailabilityEntity>) => {
                    const { shift } = row.original;
                    return (
                        <FlexBox alignItems='center'>
                            <Small mb={0.5}>{shift?.name}</Small>
                        </FlexBox>
                    );
                },
            },
            {
                minWidth: 50,
                Header: t('Date'),

                Cell: ({ row }: CellProps<ShiftAvailabilityEntity>) => {
                    const { date } = row.original;
                    return (
                        <FlexBox alignItems='center'>
                            <Small mb={0.5}>{(new Date(date)).toLocaleDateString('it-IT')}</Small>
                        </FlexBox>
                    );
                },
            },
            {
                maxWidth: 100,
                Header: t('shift.startTime'),
                Cell: ({ row }: CellProps<ShiftAvailabilityEntity>) => {
                    const startTime = row.original.shift?.startTime ? new Date(row.original.shift.startTime).
                        toLocaleTimeString(undefined, timeOption) : '';
                    return (
                        <FlexBox alignItems='center'>
                            <Small mb={0.5}>{startTime}</Small>
                        </FlexBox>
                    );
                },
            },
            {
                Header: t('shift.endTime'),
                Cell: ({ row }: CellProps<ShiftAvailabilityEntity>) => {
                    const endTime = row.original.shift?.endTime && new Date(row.original.shift.endTime).
                        toLocaleTimeString(undefined, timeOption);
                    return (
                        <FlexBox alignItems='center'>
                            <Small mb={0.5}>{endTime}</Small>
                        </FlexBox>
                    );
                },
            },
            {
                Header: '  ',
                Cell: ({ row }: CellProps<ShiftAvailabilityEntity>) => {
                    return (
                        <AppCheckBox
                            key={row.id}
                            onChange={() => {
                                props.changeRequestState(Number(row.id), !row.original.answer)
                            }}
                            defaultChecked={row.original.answer}

                        />

                    );
                },
            }
        ]
    }

    return (
        DataTable({
            ...props,
            notSelectableRows: true,
            columns,
        })

    )

}
export default ShiftRequestsDataTable;

