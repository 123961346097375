import React from 'react';
import ReactDOM from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { JWTAuthProvider } from 'contexts/JWTAuth';
import 'nprogress/nprogress.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
// import "react-image-lightbox/style.css";
import 'react-quill-new/dist/quill.snow.css';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import App from './App';
import SettingsProvider from 'contexts/settingsContext';
import reportWebVitals from './reportWebVitals';
import './__fakeData__';
import SeasonProvider from 'contexts/SeasonContext';
import EncryptionProvider from 'contexts/EncryptionKeyContext';
import HiddenProvider from 'contexts/HiddenContext';
import EmployeeProvider from 'contexts/EmployeeContext';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';
import itLocale from 'date-fns/locale/it';
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://d3cf9a2037a17459873927dd1b606d2e@o4508329790210048.ingest.de.sentry.io/4508329888776272",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/beta.one-staff\.it\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
        <SettingsProvider>
          <JWTAuthProvider>
            <SeasonProvider>
              <EncryptionProvider>
                <HiddenProvider>
                  <EmployeeProvider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </EmployeeProvider>
                </HiddenProvider>
              </EncryptionProvider>
            </SeasonProvider>
          </JWTAuthProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </I18nextProvider>

  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

