import FlexBox from "components/flexbox/FlexBox";
import { FC } from "react";
import Logo from '../../../assets/images/noData.svg';
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoData: FC = () => {
    const { t } = useTranslation();

    const pickRandomPhrase = () => {
        const random = Math.floor(Math.random() * 5);
        switch (random) {
            case 0: return t('dashboard.noData.phrase1');
            case 1: return t('dashboard.noData.phrase2');
            case 2: return t('dashboard.noData.phrase3');
            case 3: return t('dashboard.noData.phrase4')
            case 4: return t('dashboard.noData.phrase5')
            default: return t('dashboard.noData.phrase6')
        }
    }
    return (
        <FlexBox flexDirection={'column'} mt={4}>
            <FlexBox justifyContent={'center'}>
                <img src={Logo} alt='No data' style={{ maxWidth: '16rem' }} />
            </FlexBox>
            <Typography fontSize={14} fontWeight={200} textAlign={'center'} mt={4}>{pickRandomPhrase()}</Typography>
        </FlexBox>
    );
}

export default NoData;