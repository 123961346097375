import { DateRange } from '@mui/lab';
import {
    Box,
    Card,
    Switch,
    Typography,
    useTheme,
} from '@mui/material';
import { type ApexOptions } from 'apexcharts';
import { TurnoverItemEntity } from 'api/generated';
import FlexBetween from 'components/flexbox/FlexBetween';
import { H5 } from 'components/Typography';
import { eachMonthOfInterval, format, isSameMonth, isWithinInterval } from 'date-fns';
import { t } from 'i18next';
import numeral from 'numeral';
import NoData from 'page-sections/dashboards/dashboard-components/no-data';
import { useEffect, useState, type FC } from 'react';
import Chart from 'react-apexcharts';

interface TurnoverAnnualChartProps {
    periodATurnoverItems: TurnoverItemEntity[];
    periodBTurnoverItems: TurnoverItemEntity[];
    dateRangeA: DateRange<Date>;
    dateRangeB: DateRange<Date>;
}

const TurnoverAnnualChart: FC<TurnoverAnnualChartProps> = ({
    periodATurnoverItems,
    periodBTurnoverItems,
    dateRangeA,
    dateRangeB
}) => {

    // to choose between period A and B
    const [periodA, setPeriodA] = useState(true);
    const [turnoverItems, setTurnoverItems] = useState<TurnoverItemEntity[]>([]);
    const [dateRange, setDateRange] = useState<DateRange<Date>>(dateRangeA);

    useEffect(() => {
        setTurnoverItems(periodA ? periodATurnoverItems : periodBTurnoverItems);
    }, [periodA, periodATurnoverItems, periodBTurnoverItems])

    useEffect(() => {
        setDateRange(periodA ? dateRangeA : dateRangeB);
    }, [periodA, dateRangeA, dateRangeB])

    const [selectedTurnoverItems, setSelectedTurnoverTurnoverItems] = useState<TurnoverItemEntity[]>([]);
    const [chartCategories, setChartCategories] = useState<string[]>([]);

    useEffect(() => {
        setSelectedTurnoverTurnoverItems(
            turnoverItems.filter((item) => {
                return dateRange[0] && dateRange[1] && isWithinInterval(new Date(item.date), { start: dateRange[0], end: dateRange[1] });
            })
        )
    }, [turnoverItems, dateRange])

    const [series, setSeries] = useState<number[]>([]);
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => {
        if (dateRange[0] && dateRange[1]) {
            const months = eachMonthOfInterval({ start: dateRange[0], end: dateRange[1] });
            setChartCategories(months.map(month => format(month, 'MMM yy')));

            const tempSeries: number[] = [];
            let max = 0;
            for (const month of months) {
                const monthValue = turnoverItems.filter((item) => {
                    return isSameMonth(new Date(item.date), month);
                }).map((item) => {
                    return item.value;
                }).reduce((a, b) => {
                    return a + b;
                }, 0);
                tempSeries.push(monthValue)
                if (monthValue > max) {
                    max = monthValue;
                }
            }
            setSeries(tempSeries);
            setMaxValue(max);
        }
    }, [selectedTurnoverItems])

    const theme = useTheme();

    const chartOptions: ApexOptions = {
        chart: {
            background: 'transparent',
            toolbar: { show: false },
        },
        colors: [theme.palette.primary.main],
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: false,
        },
        states: {
            active: {
                filter: { type: 'none' },
            },
            hover: {
                filter: { type: 'none' },
            },
        },
        theme: {
            mode: theme.palette.mode,
        },
        xaxis: {
            axisBorder: { show: false },
            axisTicks: { show: false },
            categories: chartCategories,
            labels: {
                style: {
                    colors: theme.palette.text.secondary,
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 500,
                },
            },
        },
        yaxis: {
            show: true,
            min: 0,
            max: maxValue === 0 ? 1 : maxValue,
            tickAmount: maxValue === 0 ? 1 : 4,
            labels: {
                style: {
                    colors: theme.palette.text.secondary,
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 500,
                },
                formatter: (value) => numeral(value).format('0a'),
            },
        },
        tooltip: {
            x: { show: false },
            y: {
                formatter: (value, opts) => {
                    const numericValue = Number(value);
                    if (numericValue >= 1_000_000) {
                        return (numericValue / 1_000_000).toFixed(1) + 'M';
                    } else if (numericValue >= 1_000) {
                        return (numericValue / 1_000).toFixed(1) + 'K';
                    }
                    return numericValue.toFixed(2) + " €";
                },
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 3,
                columnWidth: '30%',
                borderRadiusApplication: 'end',
            },
        },
        responsive: [
            {
                breakpoint: 550,
                options: {
                    chart: { height: 350 },
                    plotOptions: {
                        bar: { horizontal: true },
                    },
                    xaxis: {
                        min: 0,
                        max: 15000,
                        tickAmount: 4,
                        labels: {
                            show: true,
                            style: {
                                colors: theme.palette.text.secondary,
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 500,
                            },
                            formatter: (value: number) => numeral(value).format('0a'),
                        },
                    },
                    yaxis: {
                        show: true,
                        labels: {
                            style: {
                                colors: theme.palette.text.secondary,
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: 500,
                            },
                        },
                    },
                },
            },
        ],
    };

    return (
        <Card
            sx={{
                height: '100%',
                padding: 3,
                [theme.breakpoints.down(425)]: { padding: '1.5rem' }
            }}
        >
            <FlexBetween>
                <H5 mb={3} textAlign='center'>
                    {t('turnover.annualChart.title')}
                </H5>

                <Box display={'flex'} alignItems={'center'}>
                    <Typography fontSize={12}>
                        {t('turnover.statistics.periodA')}
                    </Typography>
                    <Switch
                        checked={!periodA}
                        onClick={() => { setPeriodA(!periodA) }}
                        value="active"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        size='small'
                        color='default'
                    />
                    <Typography fontSize={12}>
                        {t('turnover.statistics.periodB')}
                    </Typography>
                </Box>
            </FlexBetween>
            {
                turnoverItems.length > 0 ? <Box
                    sx={{
                        '& .apexcharts-tooltip *': {
                            fontWeight: 500,
                            fontFamily: theme.typography.fontFamily,
                        },
                        '& .apexcharts-tooltip': {
                            boxShadow: 0,
                            borderRadius: 4,
                            alignItems: 'center',
                            '& .apexcharts-tooltip-text-y-value': { color: 'primary.main' },
                            '& .apexcharts-tooltip.apexcharts-theme-light': {
                                border: `1px solid ${theme.palette.divider}`,
                            },
                            '& .apexcharts-tooltip-series-group:last-child': {
                                paddingBottom: 0,
                            },
                        },
                    }}
                >
                    <Chart
                        type='bar'
                        height={230}
                        options={chartOptions}
                        series={[{ name: '', data: series }]}
                    />
                </Box> : <NoData />
            }
        </Card >
    );
};

export default TurnoverAnnualChart;
