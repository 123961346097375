import { buildingApi, employeesApi, roomApi } from 'api';
import { BuildingEntity, EmployeeEntity, EmployeeToSeasonEntity, RoomEntity } from 'api/generated';
import { format } from 'date-fns';
import * as ExcelJS from 'exceljs';
import { t } from "i18next";
import { addLogoImageToReport, downloadReport, reportAddTitleRow, reportCreateWoksheet, reportCreateWorkbook } from 'utils/utils';

const addHeaderRow = (
    worksheet: ExcelJS.Worksheet,
) => {
    const row = worksheet.insertRow(2,
        [
            t('housing.report.building'),
            t('housing.report.room'),
            t('housing.report.firstName'),
            t('housing.report.lastName'),
            t('housing.report.startDate'),
            t('housing.report.endDate'),
            t('housing.report.departments')
        ]
    )
    row.font = {
        name: 'Arial',
        family: 2,
        size: 12,
        bold: true,
    };
}

const addEmployeesRows = (
    worksheet: ExcelJS.Worksheet,
    empToSeasons: EmployeeToSeasonEntity[],
    employees: EmployeeEntity[],
    rooms: RoomEntity[],
    buildings: BuildingEntity[],
    columnsNumber: number
) => {
    let row = 3;
    for (const building of buildings) {
        const buildingRooms = rooms.filter((room) => { return room.buildingId === building.id });
        for (let i = 1; i <= columnsNumber; i++) {
            worksheet.getCell(row, i).border = {
                bottom: {
                    style: 'thin',
                    color: { argb: 'FF000000' }
                },
                top: {
                    style: 'thin',
                    color: { argb: 'FF000000' }
                }
            }
        }
        const buildingCell1 = worksheet.getCell(row, 1);
        buildingCell1.value = building.name;
        if (buildingRooms.length === 0) {
            const tempCell = worksheet.getCell(row, 2);
            tempCell.value = t('housing.report.emptyBuilding')
            worksheet.getCell(row, 7).merge(tempCell);
            row = row + 1;
            continue;
        }
        for (const room of buildingRooms) {
            const roomEmployees = empToSeasons.filter((emp) => { return emp.roomId === room.id })
            for (let i = 2; i <= columnsNumber; i++) {
                worksheet.getCell(row, i).border = {
                    bottom: {
                        style: 'thin',
                        color: { argb: 'FF000000' }
                    },
                    top: {
                        style: 'thin',
                        color: { argb: 'FF000000' }
                    }
                }
            }
            const roomCell1 = worksheet.getCell(row, 2);
            roomCell1.value = room.name;
            if (roomEmployees.length === 0) {
                const tempCell = worksheet.getCell(row, 3);
                tempCell.value = t('housing.report.emptyRoom')
                worksheet.getCell(row, 7).merge(tempCell);
                row = row + 1
                continue;
            }
            for (const empToSeason of roomEmployees) {
                const employee = employees.find((emp) => { return emp.id === empToSeason.employeeId });
                if (employee) {
                    for (let i = 3; i <= columnsNumber; i++) {
                        worksheet.getCell(row, i).border = {
                            bottom: {
                                style: 'thin',
                                color: { argb: 'FF000000' }
                            },
                            top: {
                                style: 'thin',
                                color: { argb: 'FF000000' }
                            }
                        }
                    }
                    worksheet.getCell(row, 3).value = employee.firstName;
                    worksheet.getCell(row, 4).value = employee.lastName;
                    if (empToSeason.contract) {
                        worksheet.getCell(row, 5).value = empToSeason.contract.startedOn ? format(new Date(empToSeason.contract.startedOn), 'dd/MM/yyyy') : '';
                        worksheet.getCell(row, 6).value = empToSeason.contract.endedOn ? format(new Date(empToSeason.contract.endedOn), 'dd/MM/yyyy') : '';
                    } else {
                        worksheet.getCell(row, 5).value = t('housing.report.noContract');
                        worksheet.getCell(row, 6).merge(worksheet.getCell(row, 5));
                    }
                    worksheet.getCell(row, 7).value =
                        employee.employeesToDepartments?.map((value) => { return value.department?.name }).toString() ||
                        t('housing.report.noDepartments')
                }
                row = row + 1;
            }
            const roomCell2 = worksheet.getCell(row - 1, 2);
            if (roomCell1.row !== roomCell2.row) roomCell2.merge(roomCell1);
        }
        const buildingCell2 = worksheet.getCell(row - 1, 1);
        if (buildingCell1.row !== buildingCell2.row) buildingCell2.merge(buildingCell1);
    }
}

const reportStyleWorksheet = (worksheet: ExcelJS.Worksheet, rowsNumber: number, columnsNumber: number) => {
    for (let i = 1; i <= rowsNumber; i++) {
        for (let j = 1; j <= columnsNumber; j++) {
            const cell = worksheet.getCell(i, j);
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            if (i % 2 === 0) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'd6eaf8' }
                };
            } else {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'aed6f1' }
                };
            }
        }
    }
}

const createReport = async (seasonId: number) => {
    const workbook = reportCreateWorkbook();
    const worksheet = reportCreateWoksheet(workbook);

    const columnsNumber = 7;

    reportAddTitleRow(worksheet, columnsNumber, t('housing.report.title'));

    const empToSeasons = (await employeesApi.getWithBuildingsInfo(seasonId)).data;
    if (empToSeasons.length === 0) {
        worksheet.insertRow(2, [t('housing.report.noEmpToSeason')]);
        await addLogoImageToReport(worksheet, workbook);
        reportStyleWorksheet(worksheet, 2, columnsNumber);
        return workbook
    };

    const rooms = (await roomApi.findAll(undefined, seasonId)).data;
    if (rooms.length === 0) {
        worksheet.insertRow(2, [t('housing.report.noRooms')]);
        await addLogoImageToReport(worksheet, workbook);
        reportStyleWorksheet(worksheet, 2, columnsNumber);
        return workbook;
    };

    const employees = (await employeesApi.findByIds(empToSeasons.map((emp) => { return emp.employeeId.toString() }), true)).data;
    if (employees.length === 0) {
        worksheet.insertRow(2, [t('housing.report.noEmpToSeason')]);
        await addLogoImageToReport(worksheet, workbook);
        reportStyleWorksheet(worksheet, 2, columnsNumber);
        return workbook;
    };

    const buildings = (await buildingApi.findAll(seasonId)).data
    if (buildings.length === 0) {
        worksheet.insertRow(2, [t('housing.report.noBuildings')]);
        await addLogoImageToReport(worksheet, workbook);
        reportStyleWorksheet(worksheet, 2, columnsNumber);
        return workbook;
    };

    const emptyRooms = rooms.filter((room) => { return !empToSeasons.find((empToSeasons) => { return empToSeasons.roomId === room.id }) });
    const emptyBuildings = buildings.filter((building) => { return !rooms.find((room) => { return room.buildingId === building.id }) });
    const rowsNumber = empToSeasons.length + 2 + emptyRooms.length + emptyBuildings.length;

    addHeaderRow(worksheet);

    addEmployeesRows(
        worksheet,
        empToSeasons,
        employees,
        rooms,
        buildings,
        columnsNumber
    );

    reportStyleWorksheet(worksheet, rowsNumber, columnsNumber);

    await addLogoImageToReport(worksheet, workbook);

    return workbook;
}

export const housingsReport = async (seasonId: number) => {
    const workbook = await createReport(seasonId);
    downloadReport(workbook);
}