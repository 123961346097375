
import { Alert, Autocomplete, Box, Button, Card, FormControlLabel, Grid, IconButton, Switch, Tooltip, Typography, useTheme } from "@mui/material";
import { calendarEntriesShiftTemplateApi, departmentsApi, employeesApi, shiftApi, workPositionsApi } from "api";
import { DepartmentEntity, EmployeeEntity, ShiftAvailabilityRequestEntity } from "api/generated";
import AppTextField from "components/input-fields/AppTextField";
import CalendarInput from "components/input-fields/CalendarInput";
import { useSeason } from "contexts/SeasonContext";
import EmployeeCards from "page-sections/shifts/employee-card";
import ResourceColumn from "page-sections/shifts/resources";
import WorkPositionColumn from "page-sections/shifts/work-positions";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ignoreUTC } from "utils/utils";
import errorImage from 'assets/images/error.png';
import PublishShiftsDatePicker from "page-sections/shifts/publishShiftDatePicker";
import { useNavigate } from "react-router-dom";
import { addDays, isSameDay } from "date-fns";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { checkTokenRole } from "utils/checkToken";
import toast, { Toaster } from "react-hot-toast";
import RequestAviability from "page-sections/shifts/requestAviability";

export interface ShiftData {
    id: number;
    index: number;
    name: string;
    width: number;
    startHour: string;
    endHour: string;
    date?: Date
}

export interface Position {
    id: number;
    workPositionId: number;
    firstName?: string;
    lastName?: string;
    employeeId?: number;
    assignmentId?: number;
    start?: Date,
    end?: Date,
    breakStartTime?: Date,
    breakEndTime?: Date,
    breakPaddingStart?: number,
    breakPaddingEnd?: number,
    date: Date,
    leftPadding: number,
    rightPadding: number,
}

const FIXED_HEIGHT = 50;


const ShiftAssignment: FC = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { seasonId } = useSeason();

    const theme = useTheme();

    const screenWidth = window.innerWidth;

    const [shifts, setShifts] = useState<ShiftData[]>([]);

    const [selectedDay, setSelectedDay] = useState(ignoreUTC(new Date()));

    const [selectedEndDay, setSelectedEndDay] = useState(addDays(ignoreUTC(new Date()), 7))

    const [workPositionsIds, setWorkPositionsIds] = useState<Array<{ id: number, name: string }>>([]);

    const [isWorkPositionVisibleSet, setIsWorkPositionVisibleSet] = useState(false);

    const [isWorkPositionVisible, setIsWorkPositionVisible] = useState<Record<number, boolean>>([]);

    const [workPositionMaxDimension, setWorkPositionMaxDimension] = useState<Record<number, number>>([]);

    const [resources, setResources] = useState<Record<number, Position[]>>([]);

    const [selectedDepartment, setSelectedDepartment] = useState<{ label: string; id: number; } | null>(null);

    const [departments, setDepartments] = useState<DepartmentEntity[]>([]);

    const [columns, setColumns] = useState(12);

    const [selectedPosition, setSelectedPosition] = useState<Position>();

    const [employees, setEmployees] = useState<EmployeeEntity[]>([]);

    const [workedHours, setWorkedHours] = useState<Record<number, number>>([]);

    const [openSendShift, setOpenSendShift] = useState(false);

    const [openAskForAviability, setOpenAskForAviability] = useState(false);

    const [multipleDays, setMultipleDays] = useState(false);

    const [countAviability, setCountAviability] = useState(true);

    const [requestsHasBeenSent, setRequestsHasBeenSent] = useState(false);

    const [overlappingRequests, setOverlappingRequests] = useState<ShiftAvailabilityRequestEntity[]>([]);


    const MIN_WIDTH = multipleDays ? 70 : Math.round(screenWidth / 40);


    const resetData = () => {
        setShifts([]);
        setWorkPositionsIds([]);
        setWorkPositionMaxDimension([]);
        setResources([]);
        setColumns(12);
        setIsWorkPositionVisibleSet(false);
    }

    const fetchHours = async (parameterEmployees: EmployeeEntity[]) => {
        const workedHours: Record<number, number> = [];
        for (const employee of parameterEmployees) {
            workedHours[employee.id] = Math.round((await shiftApi.weeklyEmployeeHours(employee.id, selectedDay.toISOString(), seasonId)).data);
        };

        return workedHours;

    }

    const fetchEmployees = async () => {
        let employees: EmployeeEntity[] = [];

        if (selectedPosition) {
            employees = (await employeesApi.findAssignableEmployees({
                params: {
                    seasonId,
                    workPositionId: selectedPosition?.workPositionId ?? 0,
                    shiftDetailId: selectedPosition.id,
                    date: selectedPosition.date.toISOString(),
                    checkAvaibility: countAviability
                }
            }

            )).data;
            if (employees.length === 0)
                toast.error(t('shift.noEmployees'), { duration: 6000 })
            setEmployees(employees);
        }
        else {
            //  employees = (await departmentsEmployeesApi.findAll(String(selectedDepartment.id), seasonId)).data.map(depToEmp => depToEmp.employee).filter(employee => !!employee) as EmployeeEntity[];
            //  setEmployees(employees);
            setEmployees([])
        }

        setWorkedHours(await fetchHours(employees));

    }

    useEffect(() => {
        setWorkPositionsVisibility();
    }, [workPositionsIds])

    useEffect(() => {
        fetchEmployees();
    }, [selectedPosition, selectedDepartment, seasonId, countAviability]);

    const fetchRequestsHasBeenSent = async () => {

        (await shiftApi.hasAvailabilityBeenSent(
            selectedDay.toISOString(), multipleDays ? selectedEndDay.toISOString() : selectedDay.toISOString(),
            seasonId, selectedDepartment?.id
        ).then(async ({ data }) => {
            if (!data)
                setCountAviability(false);
            if (multipleDays && !data) {
                setOverlappingRequests((await shiftApi.availabilityRequestsByManager(selectedDay.toISOString(), selectedEndDay.toISOString(), seasonId, selectedDepartment?.id)).data)
            }
            else {
                setOverlappingRequests([]);
            }

            setRequestsHasBeenSent(data);
        }))
    }


    useEffect(() => {
        fetchRequestsHasBeenSent();
    }, [selectedDay, selectedEndDay, multipleDays, selectedDepartment])

    const setWorkPositionsVisibility = () => {


        // if the array is already defined do not reset it
        if (isWorkPositionVisibleSet)
            return;

        const newIsWorkPositionVisible: Record<number, boolean> = [];

        workPositionsIds.forEach((id) => {
            newIsWorkPositionVisible[id.id] = true;
        });


        setIsWorkPositionVisible(newIsWorkPositionVisible);
        setIsWorkPositionVisibleSet(true);


    }


    const fetchData = async () => {

        setSelectedPosition(undefined);

        if (!selectedDepartment)
            return;

        let computedShifts: ShiftData[] = [];

        let shiftIndex = 1;


        const resources: Record<number, Position[]> = [];

        const workPositionsIds: Array<{ id: number, name: string }> = (await workPositionsApi.findAll(selectedDepartment.id, seasonId)).data;

        const templates = multipleDays ?

            (await calendarEntriesShiftTemplateApi.findByMultipleDates
                (selectedDay.toISOString(), selectedEndDay.toISOString(), selectedDepartment.id, seasonId)).data
            :

            [(await calendarEntriesShiftTemplateApi.findByDate(selectedDay.toISOString(), selectedDepartment.id, seasonId)).data]

        const localWorkPositionMaxDimension: Record<number, number> = workPositionMaxDimension;

        for (const template of templates) {


            if (!template.shiftTemplate?.shifts) {
                continue;
            }


            computedShifts = computedShifts.concat(template.shiftTemplate?.shifts?.map((shift) => {


                const start = ignoreUTC(new Date(shift.startTime), true);
                const end = ignoreUTC(new Date(shift.endTime), true)

                workPositionsIds.forEach((id) => {
                    const filtred = shift.shiftDetails?.filter(value => value.workPositionId === id.id);

                    if (filtred) {
                        if (!localWorkPositionMaxDimension[id.id])
                            localWorkPositionMaxDimension[id.id] = filtred.length;
                        else if (localWorkPositionMaxDimension[id.id] < filtred.length)
                            localWorkPositionMaxDimension[id.id] = filtred.length;
                    }

                    filtred?.forEach(detail => {

                        const startTime = detail.startTime ? ignoreUTC(new Date(detail.startTime), true) : undefined;

                        const endTime = detail.endTime ? ignoreUTC(new Date(detail.endTime), true) : undefined;

                        const breakStart = detail.breakStart ? ignoreUTC(new Date(detail.breakStart), true) : undefined;

                        const breakEnd = detail.breakEnd ? ignoreUTC(new Date(detail.breakEnd), true) : undefined;

                        let breakPaddingStart = breakStart ? breakStart.getHours() - (startTime && +startTime > +start ? startTime : start).getHours() : undefined;

                        let breakPaddingEnd = breakEnd ? breakEnd.getHours() - (startTime && +startTime > +start ? startTime : start).getHours() : undefined;

                        let leftPadding = !startTime ? 0 : -(start.getHours() - startTime.getHours());

                        let rightPadding = !endTime ? 0 : (end.getHours() - endTime.getHours());

                        // next two ifs should avoid too big resources for the column

                        if (breakPaddingStart && breakStart?.getHours() === startTime?.getHours())
                            breakPaddingStart++;

                        if (breakPaddingEnd && breakEnd?.getHours() === endTime?.getHours())
                            breakPaddingEnd--;

                        // case end exceeds midnight
                        if (endTime && end.getHours() < start.getHours() && endTime?.getHours() > start.getHours()) {
                            rightPadding += 24;
                        }

                        // case start exceeds midnight
                        if (startTime && end.getHours() < start.getHours() && startTime.getHours() < start.getHours() && startTime.getHours() < end.getHours())
                            leftPadding += 24;

                        if (breakPaddingStart === 0)
                            breakPaddingStart += 0.5;

                        /* if break is too small reduce the biggest between left and right padding to find space,
                            in case both are too small to be reduced break will become arbitarly 1 moving break start back or break end forward
                        */
                        if (breakPaddingStart !== undefined && breakPaddingEnd !== undefined && breakPaddingEnd - breakPaddingStart < 1) {
                            if (leftPadding > rightPadding && leftPadding > 0) {
                                leftPadding -= 0.5;
                                breakPaddingStart -= 0.5
                            }
                            else if (rightPadding > 0) {
                                if (breakPaddingStart > 0) {
                                    rightPadding -= 0.5;
                                    breakPaddingEnd += 0.5;
                                }
                                else {
                                    rightPadding -= 1;
                                    breakPaddingEnd += 1;
                                    breakPaddingStart += 0.5;
                                }
                            }
                            else if (breakPaddingStart > 1)
                                breakPaddingStart -= 0.5;
                            else {
                                breakPaddingStart += 0.5;
                                breakPaddingEnd += 1;
                            }


                        }

                        const firstName = detail.shiftAssignments?.at(0)?.employee ? detail.shiftAssignments?.at(0)?.employee?.firstName + ' ' : undefined;
                        const lastName = detail.shiftAssignments?.at(0)?.employee?.lastName
                        const resource = {
                            id: detail.id,
                            workPositionId: detail.workPositionId,
                            firstName,
                            lastName,
                            assignmentId: detail.shiftAssignments?.at(0)?.id,
                            employeeId: detail.shiftAssignments?.at(0)?.employee?.id,
                            start: startTime,
                            end: endTime,
                            breakStartTime: breakStart,
                            breakEndTime: breakEnd,
                            breakPaddingStart,
                            breakPaddingEnd,
                            date: ignoreUTC(new Date(template.startedOn), false),
                            leftPadding,
                            rightPadding,
                        };

                        resources[shiftIndex] ? resources[shiftIndex].push(resource) : resources[shiftIndex] = [resource];
                    })

                })

                const width = (+end > +start) ? end.getHours() - start.getHours() : end.getHours() - start.getHours() + 24;

                return {
                    id: shift.id,
                    width: width > 2 ? width : 2,
                    name: shift.name,
                    index: shiftIndex++,
                    startHour: start.toLocaleTimeString('it-IT', { hour: 'numeric', minute: 'numeric' }),
                    endHour: end.toLocaleTimeString('it-IT', { hour: 'numeric', minute: 'numeric' }),
                    date: multipleDays ? ignoreUTC(new Date(template.startedOn), false) : undefined,
                }
            }) ?? []);


        }

        if (computedShifts.length === 0) {
            resetData();
            return
        }

        setWorkPositionMaxDimension(localWorkPositionMaxDimension);

        setShifts(computedShifts);

        setWorkPositionsIds(workPositionsIds);

        setResources(resources);

        setWorkedHours(await fetchHours(employees));


    }

    const fetchDepartemnts = async () => {
        const role = checkTokenRole();
        const departments = role === 'DEPCHIEF' ? (await departmentsApi.findWhereIsBoss(seasonId)).data : (await departmentsApi.findAll()).data;
        setDepartments(departments);
        setSelectedDepartment(departments[0] ? {
            id: departments[0].id,
            label: departments[0].name
        } : null);
    }

    useEffect(() => {
        setIsWorkPositionVisibleSet(false);
        fetchData();
    }, [selectedDepartment, selectedDay, selectedEndDay, multipleDays, seasonId])

    useEffect(() => {
        fetchDepartemnts();
    }, [seasonId])

    useEffect(() => {
        setColumns(shifts.map(x => x.width).reduce((a, b) => { return a + b }, 0) + 2)
    }, [shifts])

    const sendShifts = async (startDate: Date, endDate: Date, mode: string) => {
        await shiftApi.sendShifts({
            params: {
                startDate,
                endDate,
                seasonId,
                departmentId: selectedDepartment && mode === 'department' ? +selectedDepartment.id : undefined
            }
        })
    }

    const requestAviability = async (startDate: Date, endDate: Date, mode: string) => {
        await shiftApi.createAviabilityRequests({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            seasonId,
            departmentId: selectedDepartment && mode === 'department' ? +selectedDepartment.id : undefined
        }).then(() => {
            toast.success(t('shift.availabilityRequestSuccess'))
            fetchRequestsHasBeenSent();
        }).catch(() => {
            toast.error(t('employees.alerts.error'))
        });
    }


    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Grid container mb={3} mt={1} spacing={1} alignItems={'center'}>
                <PublishShiftsDatePicker
                    onClose={() => { setOpenSendShift(false) }}
                    onSubmit={sendShifts}
                    department={selectedDepartment ?? { id: 0, label: '' }}
                    open={openSendShift}
                />
                <RequestAviability
                    onClose={() => { setOpenAskForAviability(false) }}
                    onSubmit={requestAviability}
                    department={selectedDepartment ?? { id: 0, label: '' }}
                    open={openAskForAviability}
                />

                <Grid item container xs={12} sm={2}>

                    <Grid item xs={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                        <IconButton sx={{ width: '100%' }} onClick={() => {
                            setSelectedDay(addDays(selectedDay, -1))
                        }}>
                            <ArrowBackIosIcon sx={{ display: 'flex' }} />
                        </IconButton>
                    </Grid>

                    <Grid item xs={8}>
                        <CalendarInput

                            format='dd.MM.yyyy'
                            disableFuture={false}
                            localeText={{
                                fieldDayPlaceholder: () => t("DD"),
                                fieldMonthPlaceholder: () => t("MM"),
                                fieldYearPlaceholder: () => t("YYYY"),
                            }}
                            value={selectedDay}
                            onChange={async (newValue) => {
                                if (newValue && !isNaN(new Date(newValue).getTime())) {
                                    const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                                    setSelectedDay(utcDate);
                                }
                            }}
                            label={multipleDays ? t('season.startDate') : t('report.selectDate')}
                        />
                    </Grid>

                    <Grid item xs={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                        <IconButton sx={{ width: '100%' }} onClick={() => {
                            setSelectedDay(addDays(selectedDay, 1))
                        }}>
                            <ArrowForwardIosIcon sx={{ display: 'flex' }} />
                        </IconButton>
                    </Grid>

                </Grid>
                {
                    multipleDays && (
                        <Grid item container xs={12} sm={2}>

                            <Grid item xs={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                <IconButton sx={{ width: '100%' }} onClick={() => {
                                    setSelectedEndDay(addDays(selectedEndDay, -1))
                                }}>
                                    <ArrowBackIosIcon sx={{ display: 'flex' }} />
                                </IconButton>
                            </Grid>

                            <Grid item xs={8}>
                                <CalendarInput

                                    format='dd.MM.yyyy'
                                    disableFuture={false}
                                    localeText={{
                                        fieldDayPlaceholder: () => t("DD"),
                                        fieldMonthPlaceholder: () => t("MM"),
                                        fieldYearPlaceholder: () => t("YYYY"),
                                    }}
                                    value={selectedEndDay}
                                    onChange={async (newValue) => {
                                        if (newValue && !isNaN(new Date(newValue).getTime())) {
                                            const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
                                            setSelectedEndDay(utcDate);
                                        }
                                    }}
                                    label={t('season.endDate')}
                                />
                            </Grid>

                            <Grid item xs={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                                <IconButton sx={{ width: '100%' }} onClick={() => {
                                    setSelectedEndDay(addDays(selectedEndDay, 1))
                                }}>
                                    <ArrowForwardIosIcon sx={{ display: 'flex' }} />
                                </IconButton>
                            </Grid>

                        </Grid>
                    )
                }
                <Grid item xs={12} sm={2}>
                    <Autocomplete
                        id="department-selector"
                        options={departments?.map((department) => ({
                            label: department.name,
                            id: department.id
                        })) || []}
                        value={selectedDepartment}
                        onChange={(event, value) => {
                            // work position visual must be reset
                            setIsWorkPositionVisibleSet(false);
                            setSelectedDepartment(value ? { label: value.label, id: value.id } : null);
                        }}
                        renderInput={(params) =>
                            <AppTextField
                                {...params}
                                placeholder={t('department.departmentSelector.select')}
                                label={t('department.itemName')}
                            />
                        }
                        isOptionEqualToValue={(option, value) => option.id === value?.id} // It serves to prevent alerts/errors mui in the console
                    />
                </Grid>
                <Grid item sm={2}>
                    <FormControlLabel label={t('shift.multipleDays')}
                        control={(
                            <Switch

                                value={multipleDays}
                                onChange={() => { setMultipleDays(!multipleDays) }}
                            />
                        )}
                    />

                </Grid>

                <Grid item sm={2}>
                    {
                        requestsHasBeenSent ?
                            <FormControlLabel label={t('shift.checkAviability')}
                                control={(
                                    <Switch
                                        disabled={!requestsHasBeenSent}
                                        checked={countAviability}
                                        onChange={() => { setCountAviability(!countAviability) }}
                                    />
                                )}
                            /> :
                            <Tooltip title={t('shift.noShiftRequested')}>
                                <div>
                                    <FormControlLabel label={t('shift.checkAviability')}
                                        control={(
                                            <Switch
                                                disabled={!requestsHasBeenSent}
                                                checked={countAviability}
                                                onChange={() => { setCountAviability(!countAviability) }}
                                            />
                                        )}
                                    />
                                </div>
                            </Tooltip>

                    }
                </Grid>

                <Grid item xs={12} sm={multipleDays ? 1 : 2} alignSelf={'stretch'}>
                    <Button variant="contained" sx={{ height: '100%', width: multipleDays ? '100%' : '80%' }} onClick={() => { setOpenAskForAviability(true) }}>
                        {t('shift.requestAviability')}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={multipleDays ? 1 : 2} alignSelf={'stretch'}>
                    <Button variant="contained" sx={{ height: '100%', width: multipleDays ? '100%' : '80%' }} onClick={() => { setOpenSendShift(true) }}>
                        {t('shift.publish')}
                    </Button>
                </Grid>
                {overlappingRequests.length > 0 && !requestsHasBeenSent &&
                    <Grid item xs={12}>
                        <Tooltip title={
                            <div >

                                {overlappingRequests.map((request) => {
                                    const startDate = new Date(request.startDate).toLocaleDateString('it-IT');
                                    const endDate = new Date(request.endDate).toLocaleDateString('it-IT');
                                    const createdBy = request.createdBy ? (request.createdBy.firstName) + ' ' + (request.createdBy.lastName) : t('common.deletedUser');
                                    return startDate + ' - ' + endDate + ' ' + t('employees.calendar.entry.createdBy') + ' ' + createdBy + ' ' + request.createdAt;
                                })}

                            </div>
                        }
                            placement="bottom-start"
                        >
                            <Alert severity="warning">
                                {t('shift.ovelappingRequests')}
                            </Alert>
                        </Tooltip>
                    </Grid>}
            </Grid>



            <Card>

                <Grid container minHeight={700}>
                    {
                        employees.length > 0 && (
                            <Grid item sm={2} xs={12} minWidth={MIN_WIDTH}>
                                <EmployeeCards
                                    data={employees.map((employee) => {
                                        return {
                                            id: employee.id,
                                            name: employee.firstName + ' ' + employee.lastName,
                                            workPosition: employee.employeesToDepartments?.at(0)?.workPosition?.name ?? '',
                                            workedHours: workedHours[employee.id] ?? 0,
                                            contractHours: Math.round((employee.employeesToSeasons?.at(0)?.contract?.workPercentage ?? 100) / 100 * 40),
                                            propicPath: employee.filePath
                                        }
                                    })}
                                    selectedShiftDetail={selectedPosition}
                                    fetchData={fetchData}
                                />
                            </Grid>
                        )
                    }

                    <Grid item xs={employees.length > 0 ? 10 : 12} overflow={'auto'}>
                        {shifts.length > 0 ? (
                            <Grid padding={1} container alignContent={'stretch'} columns={columns} minWidth={columns * MIN_WIDTH}>

                                <Grid item xs={2} padding={1} position={'sticky'} left={0} bgcolor={theme.palette.background.paper}>
                                    {t('menu.management.shift')}
                                </Grid>

                                {
                                    shifts.map((shift, index) => {

                                        const hasDivider = index !== 0 && !!shift.date && !!shifts[index - 1].date && !isSameDay(shift.date, shifts[index - 1].date ?? 0);

                                        return (<Grid item key={shift.index} xs={shift.width} padding={1} minWidth={MIN_WIDTH}
                                            // if this shift has a different date than previous shift put a divider
                                            borderLeft={hasDivider ? 2 : 0}
                                            borderColor={theme.palette.primary[200]}
                                        >


                                            {
                                                shift.date ? shift.name + ' ' + shift.date.toLocaleDateString('it-IT') : shift.name
                                            }
                                            <br />
                                            {
                                                shift.startHour + ' ' + shift.endHour
                                            }

                                        </Grid>)
                                    })
                                }


                                <WorkPositionColumn
                                    workPositionMaxDimension={workPositionMaxDimension}
                                    workPositionsIds={workPositionsIds}
                                    columns={columns} FIXED_HEIGHT={FIXED_HEIGHT}
                                    isWorkPositionVisible={isWorkPositionVisible}
                                    setIsWorkPositionVisible={setIsWorkPositionVisible}
                                />

                                {
                                    shifts.map((shift, index) => {
                                        return (
                                            <ResourceColumn
                                                key={shift.index}
                                                departmentId={selectedDepartment?.id ?? 0}
                                                columns={columns}
                                                FIXED_HEIGHT={FIXED_HEIGHT}
                                                setSelectedPosition={setSelectedPosition}
                                                width={shift.width}
                                                workPositionMaxDimension={workPositionMaxDimension}
                                                workPositionsIds={workPositionsIds}
                                                resources={resources[shift.index]}
                                                fetchData={fetchData}
                                                selectedPosition={selectedPosition}
                                                isWorkPositionVisible={isWorkPositionVisible}
                                                MIN_WIDTH={MIN_WIDTH}
                                                hasDivider={index !== 0 && !!shift.date && !!shifts[index - 1].date && !isSameDay(shift.date, shifts[index - 1].date ?? 0)}
                                                isMultipleDays={multipleDays}
                                                selectedDay={selectedDay}
                                            />
                                        )
                                    })
                                }

                            </Grid>


                        ) :
                            (
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    justifyContent='center'
                                    alignItems='center'
                                    minHeight='60vh' // This will center the content vertically
                                    sx={{ gap: 5 }} // This adds space between the child elements
                                >
                                    <img
                                        src={errorImage}
                                        alt='Loading image'
                                        style={{ height: '150px' }} // Adjust as needed
                                    />
                                    <Typography
                                        variant='h4'
                                        align='center'
                                        sx={{ fontWeight: 'bold' }} // Adjust as needed
                                    >
                                        {t('shift.noShiftAssigned')}
                                    </Typography>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => {
                                            navigate('/dashboard/shift-template/calendar')
                                        }}
                                        sx={{ fontSize: 'larger' }} // Makes the button text larger
                                    >
                                        {t('shift.goToCalendar')}
                                    </Button>
                                </Box>
                            )


                        }
                    </Grid>

                </Grid>
            </Card>
        </>
    )
}

export default ShiftAssignment;