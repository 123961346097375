import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Divider, Grid, useTheme } from '@mui/material';
import CardItem from 'page-sections/dashboards/Sales/InfoCardV1';
import { FC, useEffect, useState } from 'react';
import {
  EmployeeEntity,
  PaymentMethodEntity,
  SectionEntity,
  TurnoverItemEntity,
} from 'api/generated';
import {
  employeesApi,
  paymentMethodsApi,
  paymentSectionsApi,
  turnoverApi,
} from 'api';
import { subMonths, differenceInCalendarDays, isWithinInterval, format } from 'date-fns';
import { t } from 'i18next';
import TurnoverAnnualChart from './turnoverAnnualChart';
import TurnoverSectionsChart from './turnoverSectionsChart';
import { useSeason } from 'contexts/SeasonContext';
import TurnoverPaymentMethodsChart from './turnoverPaymentMethodsChart';
import LastTurnoverItems from './lastTurnoverItems';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { it } from 'date-fns/locale';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { H4 } from 'components/Typography';

const SalesV2: FC = () => {
  const { seasonId } = useSeason();

  // All items
  const [turnoverItems, setTurnoverItems] = useState<TurnoverItemEntity[]>([]);

  const [periods, setPeriods] = useState({
    periodA: {
      startDate: subMonths(new Date(), 1),
      endDate: new Date(),
      key: 'periodA'
    },
    periodB: {
      startDate: subMonths(new Date(), 2),
      endDate: subMonths(new Date(), 1),
      key: 'periodB'
    }
  });

  // Period A items
  const [periodATurnoverItems, setPeriodATurnoverItems] = useState<
    TurnoverItemEntity[]
  >([]);
  // Period B items
  const [periodBTurnoverItems, setPeriodBTurnoverItems] = useState<
    TurnoverItemEntity[]
  >([]);

  const [sections, setSections] = useState<SectionEntity[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>(
    [],
  );
  const [employees, setEmployees] = useState<EmployeeEntity[]>([]);

  const [cardList, setCardList] = useState<
    Array<{
      trend: string;
      amount: string;
      percentage: number;
      title: string;
      color: string;
    }>
  >([]);

  const fetchTurnoverItems = () => {
    turnoverApi.findAll(true).then(({ data }) => {
      setTurnoverItems(data);
    });
  };

  const fetchSections = () => {
    paymentSectionsApi.findAll(seasonId).then(({ data }) => {
      setSections(data);
    });
  };

  const fetchMethods = () => {
    paymentMethodsApi.findAll().then(({ data }) => {
      setPaymentMethods(data);
    });
  };

  const fetchEmployees = () => {
    employeesApi.getAllEmployees().then(({ data }) => {
      setEmployees(data);
    });
  };

  useEffect(() => {
    fetchTurnoverItems();
    fetchSections();
    fetchMethods();
    fetchEmployees();
  }, []);

  useEffect(() => {
    setPeriodATurnoverItems(
      turnoverItems.filter((item) => {
        return (
          isWithinInterval(new Date(item.date), { start: periods.periodA.startDate, end: periods.periodA.endDate })
        );
      })
    );

    setPeriodBTurnoverItems(
      turnoverItems.filter((item) => {
        return (
          isWithinInterval(new Date(item.date), { start: periods.periodB.startDate, end: periods.periodB.endDate })
        );
      }),
    );
  }, [turnoverItems, periods]);

  useEffect(() => {
    // First card data
    const monthEarnings = periodATurnoverItems
      .map((item) => {
        return item.value;
      })
      .reduce((a, b) => {
        return a + b;
      }, 0);
    const precedentEarnings = periodBTurnoverItems
      .map((item) => {
        return item.value;
      })
      .reduce((a, b) => {
        return a + b;
      }, 0);
    const earningsPercentage =
      ((monthEarnings - precedentEarnings) / precedentEarnings) * 100;

    // Second card data
    const averageDailyEarnings = monthEarnings / differenceInCalendarDays(periods.periodA.endDate, periods.periodA.startDate);
    const precedentAverageDailyEarnings =
      precedentEarnings / differenceInCalendarDays(periods.periodB.endDate, periods.periodB.startDate,);
    const averageEarningsPercentage =
      ((averageDailyEarnings - precedentAverageDailyEarnings) /
        precedentAverageDailyEarnings) *
      100;

    setCardList([
      {
        trend:
          averageDailyEarnings > precedentAverageDailyEarnings ? 'up' : 'down',
        amount: monthEarnings.toFixed(2).toString() + '€',
        percentage: parseFloat(earningsPercentage.toFixed(2)),
        title: t('turnover.statistics.monthEarnings'),
        color:
          averageDailyEarnings > precedentAverageDailyEarnings
            ? theme.palette.success.main
            : theme.palette.error.main,
      },
      {
        trend: monthEarnings > precedentEarnings ? 'up' : 'down',
        amount: averageDailyEarnings.toFixed(2).toString() + '€',
        percentage: parseFloat(averageEarningsPercentage.toFixed(2)),
        title: t('turnover.statistics.averageMonthEarnings'),
        color:
          monthEarnings > precedentEarnings
            ? theme.palette.success.main
            : theme.palette.error.main,
      },
    ]);
  }, [periodATurnoverItems, periodBTurnoverItems]);

  const theme = useTheme();

  return (
    <>
      <Box pt={2} pb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
              >
                <Card sx={{ padding: '1rem 1.5rem', display: 'flex', width: '100%' }}>
                  <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    <H4>{
                      t('turnover.statistics.periodA')
                    }</H4>
                    <H4>{
                      format(periods.periodA.startDate, 'dd/MM/yyyy') + ' - ' + format(periods.periodA.endDate, 'dd/MM/yyyy')
                    }</H4>
                    <Divider orientation="vertical" />
                    <H4 ml={2}>{
                      t('turnover.statistics.periodB')
                    }</H4>
                    <H4>{
                      format(periods.periodB.startDate, 'dd/MM/yyyy') + ' - ' + format(periods.periodB.endDate, 'dd/MM/yyyy')
                    }</H4>
                  </Box>
                </Card>

              </AccordionSummary>
              <AccordionDetails>
                <DateRangePicker
                  onChange={(item: any) => { setPeriods({ ...periods, ...item }) }}
                  ranges={[periods.periodA, periods.periodB]}
                  locale={it}
                  maxDate={new Date()}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={0} lg={6}></Grid>
          <Grid item xs={12} lg={3}>
            <Box width='100%' height='100%'>
              <Grid container spacing={3}>
                {cardList.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <CardItem
                      title={item.title}
                      amount={item.amount}
                      color={item.color ? item.color : ''}
                      percentage={item.percentage}
                      trend={item.trend}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} lg={9}>
            <TurnoverAnnualChart
              periodATurnoverItems={periodATurnoverItems}
              periodBTurnoverItems={periodBTurnoverItems}
              dateRangeA={[periods.periodA.startDate, periods.periodA.endDate]}
              dateRangeB={[periods.periodB.startDate, periods.periodB.endDate]}

            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TurnoverSectionsChart
              periodATurnoverItems={periodATurnoverItems}
              periodBTurnoverItems={periodBTurnoverItems}
              sections={sections}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <TurnoverPaymentMethodsChart
              periodATurnoverItems={periodATurnoverItems}
              periodBTurnoverItems={periodBTurnoverItems}
              methods={paymentMethods}
            />
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <LastTurnoverItems
              turnoverItems={turnoverItems}
              methods={paymentMethods}
              sections={sections}
              employees={employees}
            />
          </Grid>
        </Grid>
      </Box >
    </>
  );
};

export default SalesV2;
