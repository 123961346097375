import { Box, Button, Grid, Typography } from "@mui/material";
import { shiftApi } from "api";
import { ShiftAvailabilityEntity } from "api/generated";
import ShiftRequestsDataTable from "page-sections/data-table/ShiftRequestsDataTable";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import noRequestsImage from 'assets/images/no_requests.svg';
import toast, { Toaster } from "react-hot-toast";


const ShiftRequests: FC = () => {

    const [requests, setRequests] = useState<ShiftAvailabilityEntity[]>([]);

    const { t } = useTranslation();

    const [hasChanged, setHasChanged] = useState(false);

    const changeRequestState = (index: number, state: boolean) => {
        const newArray = [...requests];
        newArray[index].answer = state;
        setHasChanged(true);
        setRequests(newArray);
    }

    const fetchData = async () => {
        setRequests((await shiftApi.findMyRequests()).data);
    }

    const submit = async () => {
        await shiftApi.confirmRequests({
            array: requests.map(request => {
                return {
                    id: request.id,
                    answer: request.answer
                }
            })
        }).then(() => {
            toast.success(t('alerts.success'));
            setHasChanged(false);
            fetchData();
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Grid container>
            <Toaster/>
            {requests.length > 0 ?
                <>
                    <Grid item xs={12}>
                        <ShiftRequestsDataTable
                            data={requests}
                            changeRequestState={changeRequestState}
                        />

                    </Grid>
                    <Grid xs={11} mt={2} justifyContent={'end'} display={'flex'}>
                        <Button onClick={submit} disabled={!hasChanged} variant="contained">{t('confirm')}</Button>
                    </Grid>
                </> :

                (
                    <Box
                        display='flex'
                        width={'100%'}
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        minHeight='60vh' // This will center the content vertically
                        sx={{ gap: 5 }} // This adds space between the child elements
                    >
                        <img
                            src={noRequestsImage}
                            alt='Loading image'
                            style={{ height: '150px' }} // Adjust as needed
                        />
                        <Typography
                            variant='h4'
                            align='center'
                            sx={{ fontWeight: 'bold' }} // Adjust as needed
                        >
                            {t('shift.noRequests')}
                        </Typography>
                    </Box>
                )
            }
        </Grid>
    )
}

export default ShiftRequests;