import sanitizeHtml from 'sanitize-html';

export const appSanitizeHTML = (dirty?: string) => {

    const allowedAttributes = sanitizeHtml.defaults.allowedAttributes;

    allowedAttributes.li = ['data-list'];

    return sanitizeHtml(dirty ?? '', {
        allowedClasses: { '*': ['*'] },
        allowedAttributes
    })
}