import { Box, Card, Switch, Typography, useTheme } from '@mui/material';
import { type ApexOptions } from 'apexcharts';
import { SectionEntity, TurnoverItemEntity } from 'api/generated';
import FlexBetween from 'components/flexbox/FlexBetween';
import { H5 } from 'components/Typography';
import { t } from 'i18next';
import NoData from 'page-sections/dashboards/dashboard-components/no-data';
import { useEffect, useState, type FC } from 'react';
import Chart from 'react-apexcharts';
import { formatValue } from 'utils/utils';

interface TurnoverSectionsChartProps {
  periodATurnoverItems: TurnoverItemEntity[];
  periodBTurnoverItems: TurnoverItemEntity[];
  sections: SectionEntity[];
}

const TurnoverSectionsChart: FC<TurnoverSectionsChartProps> = ({
  periodATurnoverItems,
  periodBTurnoverItems,
  sections,
}) => {
  const [values, setValues] = useState(new Map<string, number>());

  // to choose between period A and B
  const [periodA, setPeriodA] = useState(true);
  const [turnoverItems, setTurnoverItems] = useState<TurnoverItemEntity[]>([]);

  useEffect(() => {
    setTurnoverItems(periodA ? periodATurnoverItems : periodBTurnoverItems);
  }, [periodA, periodATurnoverItems, periodBTurnoverItems])

  useEffect(() => {
    const map = new Map<string, number>();
    for (const section of sections) {
      const result = turnoverItems
        .filter((item) => {
          return item.paymentSectionId === section.id;
        })
        .map((item) => {
          return item.value;
        })
        .reduce((a, b) => {
          return a + b;
        }, 0);
      if (result > 0) {
        map.set(section.name, result);
      }
    }
    setValues(map);
  }, [turnoverItems, sections]);

  const theme = useTheme();

  const chartOptions: ApexOptions = {
    colors: [
      theme.palette.primary.main,
      theme.palette.info.main,
      theme.palette.warning.main,
      theme.palette.grey[400],
    ],
    chart: {
      stacked: false,
      background: 'transparent',
      sparkline: { enabled: true },
      fontFamily: theme.typography.fontFamily,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: t('total'),
              fontSize: '14px',
              fontWeight: 500,
              color: theme.palette.text.disabled,
              formatter: function (w) {
                const value = (
                  w.globals.seriesTotals
                    .reduce((a: number, b: number) => {
                      return a + b;
                    }, 0));
                return formatValue(value);
              },
            },
            value: {
              show: true,
              fontSize: '28px',
              fontWeight: 600,
              formatter: (val) => val,
            },
          },
        },
      },
    },
    states: {
      normal: { filter: { type: 'none' } },
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
    labels: Array.from(values.keys()),
    theme: { mode: theme.palette.mode },
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '13px',
      fontWeight: 500,
      itemMargin: { horizontal: 20, vertical: 5 },
      onItemClick: { toggleDataSeries: false },
      onItemHover: { highlightDataSeries: false },
    },
    tooltip: {
      style: { fontSize: '13px' },
      y: {
        formatter: (val: number) => {
          return val.toFixed(2) + '€';
        },
      },
    },
    stroke: { width: 0 },
  };

  return (
    <Card sx={{ padding: 3, height: '100%' }}>

      <FlexBetween>
        <H5 mb={3} textAlign='center'>
          {t('turnover.sectionsChart.title')}
        </H5>

        <Box display={'flex'} alignItems={'center'}>
          <Typography fontSize={12}>
            {t('turnover.statistics.periodA')}
          </Typography>
          <Switch
            checked={!periodA}
            onClick={() => { setPeriodA(!periodA) }}
            value="active"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            size='small'
            color='default'
          />
          <Typography fontSize={12}>
            {t('turnover.statistics.periodB')}
          </Typography>
        </Box>
      </FlexBetween>

      {
        turnoverItems.length > 0 && sections.length > 0 ? <Chart
          height={320}
          type='donut'
          options={chartOptions}
          series={Array.from(values.values())}
        /> : <NoData />
      }

    </Card>
  );
};

export default TurnoverSectionsChart;
