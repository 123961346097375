import { Small, Tiny } from "components/Typography";
import FlexBox from "components/flexbox/FlexBox";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "./DataTable";
import { SectionEntity } from "api/generated";
import { Button } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

interface DataTableProps {
  data?: SectionEntity[];
  handleRowSelect: (rows: []) => void;
  editCallback: (data: SectionEntity) => void;
  openAddTurnoverModal: (section: SectionEntity) => void;
}


const PaymentSectionsDataTable: FC<DataTableProps> = (props) => {
  const { t } = useTranslation()

  const columns: any = useMemo(() => getColumnShape(props.data), [t]);

  function handleModify(data: SectionEntity) {
    props.editCallback(data);
  };

  function getColumnShape(data: any) {
    return [
      {
        minWidth: 50,
        Header: t('departments.name'),
        accessor: 'name',
        Cell: ({ row }: any) => {
          const { name, id } = row.original;
          return (
            <FlexBox alignItems='center'>
              <FlexBox flexDirection='column' ml={1.2}>
                <Small mb={0.5}>{name}</Small>
                <Tiny sx={{ fontSize: 10, color: 'text.secondary' }}>
                  ID: {id}
                </Tiny>
              </FlexBox>
            </FlexBox>
          );
        }
      },
      {
        minWidth: 50,
        Header: t('paymentMethod.description'),
        accessor: 'description',
        Cell: ({ row }: any) => {
          const { description } = row.original;
          return (
            <FlexBox alignItems='center'>
              <FlexBox flexDirection='column' ml={1.2}>
                <Small mb={0.5}>{description}</Small>
              </FlexBox>
            </FlexBox>
          );
        }
      },
      {
        minWidth: 50,
        Header: t('paymentSection.taxPercentage'),
        accessor: 'taxPercentage',
        Cell: ({ row }: any) => {
          const { taxPercentage } = row.original;
          return (
            <FlexBox alignItems='center'>
              <FlexBox flexDirection='column' ml={1.2}>
                <Small mb={0.5}>{taxPercentage} %</Small>
              </FlexBox>
            </FlexBox>
          );
        }
      },
      {
        Header: t('paymentSection.onlyToBeShowed'),
        Cell: ({ row }: any) => {
          const { onlyToBeShowed } = row.original;
          return (
                onlyToBeShowed ? <CheckBox/> : <CheckBoxOutlineBlank/>
          );
        }
      },
      {
        minWidth: 50,
        Header: t('paymentSection.department'),
        accessor: 'department',
        Cell: ({ row }: any) => {
          const { department } = row.original;
          return (
            <FlexBox alignItems='center'>
              <FlexBox flexDirection='column' ml={1.2}>
                <Small mb={0.5}>{department.name}</Small>
              </FlexBox>
            </FlexBox>
          );
        }
      },
      {
        Header: t('employees.agreements.dataTable.modify'),
        Cell: ({ row }: any) => {
          return (
            <>
              <Button
                onClick={() => {
                  handleModify(row.original)
                }}
              >
                {t('common.tables.button.editWithItem', {
                  item: t('amountSection.item')
                })}
              </Button>
            </>
          );
        },
      },
      {
        Header: t('amount.add'),
        Cell: ({ row }: any) => {
          return (
            <>
              <Button
                onClick={() => {
                  props.openAddTurnoverModal(row.original);
                }}
              >
                {t('common.tables.button.addItem', {
                  item: t('amount.item')
                })}
              </Button>
            </>
          );
        },
      },
    ]
  }

  return DataTable({
    ...props,
    columns,
  });
}

export default PaymentSectionsDataTable;