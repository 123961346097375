import { FC, useEffect, useMemo, useRef, useState } from 'react';
import DataTable from './DataTable';
import AppAvatar from '../../components/avatars/AppAvatar';
import defaultProfileImage from '../../assets/images/default-profile.png';
import { EmployeeEntity } from '../../api/generated';
import { useTranslation } from 'react-i18next';
import { IconButton, Icon, Tooltip, MenuItem, Menu, Box } from '@mui/material';
import { Description } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Small } from '../../components/Typography';
import { employeesApi } from '../../api';
import {  format, } from 'date-fns';
import LocalIconImage from '../../assets/images/excelDownload.png';
import { createReportForUser } from '../../utils/report';
import Calender from '../../icons/duotone/Calender';
import WhatsappIconImage from '../../assets/images/whatsapp-icon.png';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import '../../assets/css/underLineAnimation.css';
import { useSeason } from 'contexts/SeasonContext';
import { useEmployeeContext } from 'contexts/EmployeeContext';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { appSanitizeHTML } from 'utils/appSanitizeHTML';
import { decryptData } from 'utils/encryptionDecryptionAgreements';
import { useHidden } from 'contexts/HiddenContext';
import { useEncryption } from 'contexts/EncryptionKeyContext';

interface DataTableProps {
  data?: any[];
  clearFilter?: string;
  onFilterChange?: (filters: any) => void;
  handleRowSelect: (rows: []) => void;
}







const AllEmployeesDataTable: FC<DataTableProps> = (props) => {
  const { seasonId } = useSeason();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [moreMenuAnchor, setMoreMenuAnchor] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const employeeContext = useEmployeeContext();
  const { hidden } = useHidden();
  const { encryptionKey } = useEncryption();

  const handleClickMore = (event: any, row: any) => {
    setMoreMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCloseMore = () => {
    setMoreMenuAnchor(null);
    setSelectedRow(null);
  };

  const viewEmployee = (row: any) => {
    const employee = row.original as EmployeeEntity;
    employeeContext.setEmployeeId(employee?.id);
    navigate('/dashboard/employees/new', {
      state: {
        employeeId: employee?.id,
      },
    });
  };

  


  const fetchUserDataAndCreateReport = (row: any, t: any) => {
    employeesApi
      .findOne(row.original.id.toString(), { params: { includeBankingInformation: true, includeContract: true, includeAgreements: true, includeRoom: true, includeNotes: true, seasonId } })
      .then(async (response: { data: any; }) => {
        const date = format(new Date(), 'dd/MM/yyyy');
        const fileName = `Report_${row.original.firstName}_${row.original.lastName}_${date}.xlsx`;
        await createReportForUser(response.data, fileName, t);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const openCalendar = (row: any) => {
    const employee = row.original as EmployeeEntity;
    navigate('/dashboard/employees/calendar/false', {
      state: {
        employeeId: employee?.id,
      },
    });
  };

  const openNotes = (row: any) => {
    const employee = row.original as EmployeeEntity;
    navigate('/dashboard/employees/notes', {
      state: {
        employeeRole: employee.role,
        employeeId: employee.id,
        employeeName: employee.firstName + ' ' + employee.lastName,
        employeeAvatar: employee.filePath || defaultProfileImage
      },
    });
  };

  const openReport = (row: any) => {
    const employee = row.original as EmployeeEntity;
    navigate('/dashboard/employees/report', {
      state: {
        employeeId: employee.id,
      },
    });
  };

  const handleWhatsAppClick = (phone: string) => {
    const whatsappURL = `https://wa.me/${phone.replace(/\s+/g, "")}`;
    window.open(whatsappURL);
  }


  const isIncomplete = (employee: EmployeeEntity) => {

    // this array of strings containts missing data names translated
    const incompleteFields: string[] = [];

    if (!employee.bankingInformation?.iban)
      incompleteFields.push(t('employees.bank.iban'));

    if (!employee.email)
      incompleteFields.push(t('employees.generalInformation.email'));

    if (!employee.phone)
      incompleteFields.push(t('employees.generalInformation.phone'));

    if (!employee.dateOfBirth)
      incompleteFields.push(t('employees.generalInformation.dateOfBirth.label'));

    if (!employee.countryOfBirth)
      incompleteFields.push(t('employees.generalInformation.country'));

    // province and town should be marked as missing only if Italy is selected as country of birth
    if (employee.countryOfBirth === 'IT') {
      if (!employee.provinceOfBirth)
        incompleteFields.push(t('employees.generalInformation.province'));

      if (!employee.townshipOfBirth)
        incompleteFields.push(t('employees.generalInformation.township'));
    }


    return incompleteFields;
  }



  const getColumnShape = (data: any) => {
    return [
      {
        Header: t('employees.generalInformation.name'),
        accessor: (row: any) => `${row.firstName} ${row.lastName}`,
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeEntity;
          const displayAvatar = employee.filePath || defaultProfileImage;

          const [isTall, setIsTall] = useState(false);
          const divRef = useRef<HTMLElement>(null);

          // Check the height of the box to assign the css style  
          useEffect(() => {
            if (divRef.current && divRef.current.offsetHeight > 30) {
              setIsTall(true);
            }
          }, []);
          return (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => { viewEmployee(row) }}
              className={`hover-underline-animation-parent`}
            >
              <AppAvatar src={displayAvatar} />
              <Box className='box' ref={divRef} style={{ marginLeft: '10px' }}>
                <span className={`${isTall ? 'hover-underline-animation' : ''}`}>
                  {`${employee?.firstName} `}
                </span>
                <span className={`${isTall ? 'hover-underline-animation' : ''}`}>
                  {`${employee?.lastName}`}
                </span>
              </Box>
            </Box>

          )
        }
      },
      
      {
        Header: '✓',
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeEntity;
          const incompelteFields = isIncomplete(employee);
          return incompelteFields.length > 0 ? (
            <Tooltip title={
              <>
                <div>{t('employees.generalInformation.status.incompleteFields')}: </div>
                {
                  // map fields to show theme as a vertical list
                  incompelteFields.map((field) => {
                    return <div key={field}> •  {field} </div>
                  })
                }

              </>



            }>
              <div>
                <Small
                  sx={{
                    backgroundColor: 'warning.main',
                    color: 'background.paper',
                    borderRadius: '50%',
                    width: '1rem',
                    height: '1rem',
                    display: 'flex',
                  }}
                />
              </div>
            </Tooltip>

          ) : (
            <Small
              sx={{
                backgroundColor: 'success.main',
                color: 'background.paper',
                borderRadius: '50%',
                width: '1rem',
                height: '1rem',
                display: 'flex',
              }}
            />
          );
        },
      },
      {
        Header: 'Chat',
        Cell: ({ row }: any) => {
          const isPhoneAvailable = row.original.phone;
          return (
            <IconButton
              onClick={() => {
                if (isPhoneAvailable) {
                  handleWhatsAppClick(row.original.phone);
                }
              }}
              style={{ cursor: isPhoneAvailable ? 'pointer' : 'default' }}
              disabled={!isPhoneAvailable}
            >
              <Icon
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '18px',
                  height: '18px',
                }}>
                <img src={WhatsappIconImage} alt="Whatsapp Icon" style={{ width: '30px', height: '20px', filter: isPhoneAvailable ? 'none' : 'grayscale(100%)', }} /></Icon>
            </IconButton>
          );
        },
      },
      {
        Header: t('notes.collectionName'),
        Cell: ({ row }: any) => {
          const employee = row.original as EmployeeEntity;
          const isNotesAvailable = Boolean(employee.id);
          
          const isEncrypted = employee.notes?.[employee.notes.length - 1]?.text_encrypted !== '';
          let note = isEncrypted ?
            employee.notes?.[employee.notes.length - 1]?.text_encrypted : employee.notes?.[employee.notes.length - 1]?.text;

          if (isEncrypted && !hidden)
            note = decryptData(note ?? '', encryptionKey)
          else if(isEncrypted)
            note = '';


          return (
            <Tooltip title={
              <div
                className='ql-editor'
                dangerouslySetInnerHTML={{ __html: appSanitizeHTML(note) }}
              />}>
              <IconButton
                onClick={() => { openNotes(row) }}
                style={{ cursor: isNotesAvailable ? 'pointer' : 'default' }}
                disabled={!isNotesAvailable}
              >
                <Description sx={{ color: 'primary.main', fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        Header: t('mainTable.more'),
        Cell: ({ row }: any) => {
          return (
            <IconButton onClick={(event) => { handleClickMore(event, row) }}>
              <MoreHorizIcon fontSize="small" />
            </IconButton>
          );
        },
      },
    ];
  };
  const columns: any = useMemo(() => getColumnShape(props.data), [t, hidden, encryptionKey]);


  return (
    <>

      {selectedRow && (
        <Menu
          anchorEl={moreMenuAnchor}
          open={Boolean(moreMenuAnchor)}
          onClose={() => {
            handleCloseMore();
          }}
        >

          <MenuItem
            onClick={() => { viewEmployee(selectedRow) }}
            sx={{
              width: '220px',
            }}
          >
            <span style={{ fontSize: '14px' }}>{t('common.tables.button.view')}</span>
            <VisibilityIcon sx={{ color: 'text.disabled', fontSize: 16, marginLeft: 'auto' }} />
          </MenuItem>

          <MenuItem
            onClick={() => { openCalendar(selectedRow) }}
          >
            <span style={{ fontSize: '14px' }}>{t('employees.calendar.openCalendar')}</span>
            <Calender sx={{ color: 'text.disabled', fontSize: 14, marginLeft: 'auto' }} />
          </MenuItem>



          <MenuItem
            onClick={() => { openReport(selectedRow) }}
          >
            <span style={{ fontSize: '14px' }}>{t('report.reportname')}</span>
            <AssessmentIcon sx={{ color: 'text.disabled', fontSize: 16, marginLeft: 'auto' }} />
          </MenuItem>

          <MenuItem
            onClick={() => { fetchUserDataAndCreateReport(selectedRow, t); }}
          >
            <span style={{ fontSize: '14px' }}>{t('report.name')}</span>
            <Icon
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 16,
                marginLeft: 'auto'
              }}>
              <img src={LocalIconImage} alt="Excel Icon" style={{ width: '28px', height: '18px' }} />
            </Icon>
          </MenuItem>
        </Menu>
      )}
      {DataTable({
        ...props,
        columns,
      })}
    </>
  );
};

export default AllEmployeesDataTable;