import { Button, Grid, styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import AppModal from "components/AppModal";
import { H2 } from "components/Typography";
import CalendarInput from "components/input-fields/CalendarInput";
import { addDays } from "date-fns";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEndDateValid, isValidDate } from "utils/dateValidator";
import * as Yup from 'yup';


interface ModalProps {
    open: boolean;
    department: { id: number, label: string };
    onClose: () => void;
    onSubmit: (startDate: Date, endDate: Date, mode: string) => void;
}


const StyledAppModal = styled(AppModal)(({ theme }) => ({
    maxWidth: 700,
    minWidth: 300,

    [theme.breakpoints.down(325)]: { maxWidth: '100%' },
}));


const RequestAviability: FC<ModalProps> = ({ open, onClose, onSubmit, department }) => {




    const { t } = useTranslation();


    const [mode, setMode] = useState('department');


    const fieldValidationSchema = Yup.object().shape({
        startDate: Yup.date()
            .typeError(t('employees.agreements.validation.typeError'))
            .required(t('common.forms.field.required', {
                field: t('employees.agreements.field.date'),
            }))
            .test('is-valid-date', 'Invalid date', (value) => {
                return isValidDate(value);
            }),
        endDate: Yup.date()
            .typeError(t('employees.agreements.validation.typeError'))
            .required(t('common.forms.field.required', {
                field: t('employees.agreements.field.date'),
            }))
            .test('isValidDate', 'Invalid date', (value) => {
                return isValidDate(value);
            })
            .test('isEndDateValid', t('employees.agreements.validation.endDateTime'), function (value) {
                return isEndDateValid(value, this.parent.startDate);
            }),
    });


    const initialValues = {
        startDate: addDays(new Date(), 7),
        endDate: addDays(new Date(), 14),
    };



    const { values, errors, handleSubmit, setFieldValue, setValues } = useFormik(
        {
            initialValues,
            validationSchema: fieldValidationSchema,
            onSubmit: ((values) => {
                onSubmit(values.startDate, values.endDate, mode);
                onClose();
            })
        }
    )


    useEffect(() => {
        setValues(initialValues);
        setMode('department')
    }, [open])

    return (
        <StyledAppModal
            open={open}
            handleClose={() => { onClose() }}
        >
            <form onSubmit={handleSubmit}>
                <Grid container justifyContent='space-between' spacing={1}>
                    <Grid item xs={12}>
                        <H2> {t('shift.selectDate')} </H2>
                    </Grid>
                    <Grid item xs={12} marginBottom={2} marginTop={1}>
                        {t('shift.request') + ': '}
                        <ToggleButtonGroup
                            color="primary"
                            sx={{ marginLeft: 2 }}
                            value={mode}
                            exclusive
                            onChange={(e, value) => {
                                setMode(value)
                            }}
                        >
                            <ToggleButton value={'department'}>
                                {t('department.itemName') + ': ' + department.label}
                            </ToggleButton>
                            <ToggleButton value={'all'}>
                                {t('common.all')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={6} marginBottom={2}>
                        <CalendarInput
                            format='dd.MM.yyyy'
                            label={t('season.startDate')}
                            value={values.startDate}
                            onChange={(value) => {
                                if (value && !isNaN(+value))
                                    setFieldValue('startDate', value);
                            }}
                            slotProps={{
                                textField: {
                                    error: !!errors.startDate,
                                    helperText: errors.startDate as any ?? ''
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} marginBottom={2}>
                        <CalendarInput
                            format='dd.MM.yyyy'
                            label={t('season.endDate')}
                            value={values.endDate}
                            onChange={(value) => {
                                if (value && !isNaN(+value))
                                    setFieldValue('endDate', value);
                            }}
                            slotProps={{
                                textField: {
                                    error: !!errors.endDate,
                                    helperText: errors.endDate as any ?? ''
                                }
                            }}
                        />
                    </Grid>


                    <Grid item xs={5}>
                        <Button fullWidth size='small' variant='outlined' onClick={() => { onClose() }}>
                            {t('common.forms.button.cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button fullWidth size='small' type="submit" variant='contained'>
                            {t('request')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </StyledAppModal>
    )

}

export default RequestAviability;